import { css } from 'styled-components';

export const hoverEtc = (...args) => css`
	&:hover,
	&:focus,
	&:active {
		${css(...args)};
	}
`;

export const before =
	(content = '') =>
	(...args) => css`
		&:before {
			content: '${content}';
			${css(...args)};
		}
	`;

export const after =
	(content = '') =>
	(...args) => css`
		&:after {
			content: '${content}';
			${css(...args)};
		}
	`;
