import styled from 'styled-components';

import {
	twoColumnGridBreakpoint,
	mq,
	extraExtraExtraLarge,
} from '@dop/shared/styleHelpers/mediaQueries';
import { GridItem } from '@dop/shared/components/layout/gridItem';

import { getGridItemWidth } from '../../styles/includes/grid';

export const ColumnQuarter = styled(GridItem)`
	width: ${getGridItemWidth('100%')};
	${mq({
		from: twoColumnGridBreakpoint,
	})`
		width: ${getGridItemWidth('50%')};
	`};
	${mq({
		from: extraExtraExtraLarge,
	})`
		width: ${getGridItemWidth('25%')};
	`};
`;
