import React from 'react';
import styled, { css } from 'styled-components';

export const mediaCss = ({ inline = false, alignItems = 'center' }) => css`
	display: ${inline ? 'inline-flex' : 'flex'};
	flex-direction: row;
	align-items: ${alignItems};
`;

export const mediaFigureCss = css`
	flex: none;
`;

export const mediaTextCss = css`
	max-width: none;
	flex: auto;
`;

const Media_ = (
	{ children, className = '', inline = false, alignItems = 'center' },
	ref,
) => {
	if (children == null) return null;

	return (
		<div
			ref={ref}
			className={className}
			css={`
				${mediaCss({ inline, alignItems })}
			`}
		>
			{children}
		</div>
	);
};

/**
 * @typedef {object} MediaObject
 * @property {React.FC<any>} Text
 * @property {React.FC<any>} Figure
 * @property {React.FC<any>} EmptyFigure
 * @property {React.FC<any>} EmptyText
 *
 *
 * @deprecated - use Design system component 'Shelf' instead
 * @type {MediaObject & React.FC<any>}
 */
export const Media = React.forwardRef(Media_);

Media.EmptyFigure = styled.div`
	${mediaFigureCss};
`;

Media.Figure = ({ children, as = 'div' }) => {
	if (children == null) return null;

	return (
		<styled.div
			css={`
				${mediaFigureCss}
			`}
			as={as}
		>
			{children}
		</styled.div>
	);
};

Media.Text = ({ children, as = 'div' }) => {
	if (children == null) return null;

	return (
		<styled.div
			css={`
				${mediaTextCss}
			`}
			as={as}
		>
			{children}
		</styled.div>
	);
};

Media.EmptyText = styled.div`
	${mediaTextCss};
`;
