import { usePageDataSelector } from '@/globals/dataProviders/usePageDataSelector';
import React from 'react';
import styled, { css } from 'styled-components';
import { Map } from 'immutable';

import { mapToComponent } from '@dop/shared/helpers/reactHelpers';
import { getIn, map, get } from '@dop/shared/helpers/immutableHelpersWithArgs';
import { isFilled } from '@dop/shared/helpers/immutableHelpers';
import { ThemaIcon } from '@dop/ui-icons/themaIcon';
import { LayoutGridContainer } from '@dop/shared/components/layout/layoutGridContainer';

import { BlockLink } from '../../link/blockLink';
import { hemelblauw, mvp } from '../../../styles/colors';
import { hoverEtc } from '../../../styles/mixins/pseudo';
import { Link } from '../../link/link';
import { hideUnderline, underline } from '../../../styles/includes/underline';
import { defaultTheme, space } from '../../../styles/defaultTheme';
import { ColumnQuarter } from '../../layout/columnQuarter';
import { Heading } from '../heading';
import { addTestSelector } from '@dop/shared/helpers/testSelector';

const iconSize = '3rem';

const ThemaIconStyled = styled(ThemaIcon)`
	width: ${iconSize};
	height: ${iconSize};
	fill: inherit;
	margin-bottom: 0.2rem;
`;

const HomeThemaLinkContainer = styled(ColumnQuarter)`
	margin-bottom: ${defaultTheme.halfSpace};
	margin-top: ${defaultTheme.halfSpace};
`;

const ArticleLink = styled(Link)`
	${hideUnderline};

	${hoverEtc`
		${underline};
	`};
`;

const HomeThemaLinkStyled = styled(BlockLink)`
	color: ${hemelblauw.default};
	fill: ${mvp.icons};

	${hoverEtc`
		border-bottom: none;
		color: ${hemelblauw.default};
		fill: ${mvp.icons};
	`};
`;

const HomeThemaLinkText = styled(BlockLink.Underliner)`
	color: inherit;
	fill: inherit;
	hyphens: auto;
`;

const ArticleLinkList = styled.div`
	margin-top: 0.4rem;
`;

const DIVIDERS = {
	comma: ', ', // an ordinay comma
	bullet: ' • ', // a real bullet, not middot
	pipe: ' ∣ ', // not your ordinary |
	dash: ' — ', // an em dash with thin spaces
	hyphen: ' ⁃ ', // a hyphen bullet
	space: ' ', // em space
	indent: ' ', // en quad
	leftquote: ' ‹ ', // french single left quote
	rightquote: ' › ', // french single right quote
	ellipsis: ' …', // three dots in just one character
	more: ' meer ›', // the word, with a french single right quote
};

const getDivider = (type) => {
	return Object.prototype.hasOwnProperty.call(DIVIDERS, type)
		? DIVIDERS[type]
		: null;
};

const Divider = styled.span`
	${({ $hideFirst }) =>
		Boolean($hideFirst) &&
		css`
			&:first-of-type {
				display: none;
			}
		`}
	${({ $hideLast }) =>
		Boolean($hideLast) &&
		css`
			&:last-of-type {
				display: none;
			}
		`}
	${({ $breakBefore }) =>
		Boolean($breakBefore) &&
		css`
			&:not(:first-of-type)::before {
				content: '\\a';
				white-space: pre;
			}
		`}
	${({ $breakAfter }) =>
		Boolean($breakAfter) &&
		css`
			&::after {
				content: '\\a';
				white-space: pre;
			}
		`}
`;

const HomeThemaLink = ({
	href,
	text,
	icon,
	articleLinkList,
	dividers = Map({
		before: null,
		after: Map({ type: 'comma' }),
		last: Map({ type: 'ellipsis' }),
	}),
}) => {
	return (
		<HomeThemaLinkContainer>
			<Heading
				level={3}
				css={`
					margin-top: 0;
				`}
			>
				<HomeThemaLinkStyled
					to={href}
					linkType="themaLinkTitle"
					{...addTestSelector('uiThemaLinkTitle')}
				>
					<ThemaIconStyled aria-hidden icon={icon} role="img" />
					<HomeThemaLinkText>{text}</HomeThemaLinkText>
				</HomeThemaLinkStyled>
			</Heading>
			{isFilled(articleLinkList) && (
				<ArticleLinkList>
					{map((articleLink, index) => {
						return (
							<React.Fragment key={index}>
								{get('before')(dividers) ? (
									<Divider
										{...(getIn(['before', 'hideFirst'])(dividers) && {
											$hideFirst: true,
										})}
										{...(getIn(['before', 'breakBefore'])(dividers) && {
											$breakBefore: true,
										})}
									>
										{getDivider(getIn(['before', 'type'])(dividers))}
									</Divider>
								) : null}
								<ArticleLink
									to={get('href')(articleLink)}
									linkType="themaLink"
									{...addTestSelector('uiThemaLink')}
								>
									{get('text')(articleLink)}
								</ArticleLink>
								{get('after')(dividers) ? (
									<Divider
										{...(getIn(['after', 'hideLast'])(dividers) && {
											$hideLast: true,
										})}
										{...(getIn(['after', 'breakAfter'])(dividers) && {
											$breakAfter: true,
										})}
									>
										{getDivider(getIn(['after', 'type'])(dividers))}
									</Divider>
								) : null}
							</React.Fragment>
						);
					})(articleLinkList)}
					{dividers.last ? (
						getIn(['last', 'type'])(dividers) === 'more' ? (
							<ArticleLink
								to={href}
								css={`
									font-weight: bold;
									${getIn(['last', 'breakBefore'])(dividers)
										? `
										&::before {
											content: '\\a';
											white-space: pre;
										}
									`
										: ``}
								`}
								linkType="themaLinkMore"
							>
								{getDivider(getIn(['last', 'type'])(dividers))}
							</ArticleLink>
						) : (
							<Divider
								{...(getIn(['last', 'breakBefore'])(dividers) && {
									$breakBefore: true,
								})}
							>
								{getDivider(getIn(['last', 'type'])(dividers))}
							</Divider>
						)
					) : null}
				</ArticleLinkList>
			)}
		</HomeThemaLinkContainer>
	);
};

const HomeThemaLinksContainer = styled(LayoutGridContainer)`
	margin-bottom: ${space};
`;

export const HomeThemaLinks = () => {
	const modLinkList = usePageDataSelector(getIn(['themaLinkList', 'linkList']));

	return (
		<HomeThemaLinksContainer>
			{mapToComponent(HomeThemaLink)(modLinkList)}
		</HomeThemaLinksContainer>
	);
};
