import { css } from 'styled-components';
import theme from '../theme';
import { toNegative } from '@dop/shared/styleHelpers/unitCalculationHelpers';

export const getGridItemWidth = (widthPercentage) =>
	`calc(${widthPercentage} - ${theme.space} - 0.01rem)`; // tiny extra space to prevent FF bug (see: https://kamervankoophandel.atlassian.net/browse/DOP-9045)

export const pad = css`
	padding-left: ${theme.halfSpace};
	padding-right: ${theme.halfSpace};
`;

export const padTop = css`
	padding-top: ${theme.quarterSpace};
`;

export const padBottom = css`
	padding-bottom: ${theme.quarterSpace};
`;

export const padVertical = css`
	padding-top: ${theme.quarterSpace};
	padding-bottom: ${theme.quarterSpace};
`;

export const smallMargin = css`
	margin-top: ${theme.halfSpace};
	margin-bottom: ${theme.halfSpace};
`;

export const extraSmallMargin = css`
	margin-top: ${theme.quarterSpace};
	margin-bottom: ${theme.quarterSpace};
`;

export const smallTopMargin = css`
	margin-top: ${theme.halfSpace};
`;

export const mediumMargin = css`
	margin-top: ${theme.space};
	margin-bottom: ${theme.space};
`;

export const bigMargin = css`
	margin-top: 3rem;
	margin-bottom: 3rem;
`;

export const bigBottomMargin = css`
	margin-bottom: 3rem;
`;

export const mediumBottomMargin = css`
	margin-bottom: ${theme.space};
`;

export const smallBottomMargin = css`
	margin-bottom: ${theme.halfSpace};
`;

export const extraSmallBottomMargin = css`
	margin-bottom: ${theme.quarterSpace};
`;

export const smallSideMargin = css`
	margin-left: ${theme.quarterSpace};
	margin-right: ${theme.quarterSpace};
`;

export const sideMargin = css`
	margin-left: ${theme.halfSpace};
	margin-right: ${theme.halfSpace};
`;

export const sublayoutContainer = css`
	margin-left: ${toNegative(theme.halfSpace)};
	margin-right: ${toNegative(theme.halfSpace)};
`;

export const sublayoutContainerSmall = css`
	margin-left: ${toNegative(theme.quarterSpace)};
	margin-right: ${toNegative(theme.quarterSpace)};
`;

// Returns the value for the with of al column of a certain ratio
// It takes the gutter space into account
// Example:
// calcColumnWidth(1/1) -> calc(100% - 2rem)
// calcColumnWidth(1/2) -> calc(50% - 2rem)
// we subtract a little extra margin to prevent rounding-error issue in FF
export const calcColumnWidth = (ratio) => {
	return `calc(${ratio} * 100% - ${theme.space} - 0.01rem)`;
};
