import { css } from 'styled-components';

export const accAlwaysHide = css`
	position: absolute;
	left: -999em;
	top: 0;
	height: 1px;
	overflow: hidden;
`;

/**
 * Undo accAlwaysHide
 */
export const accAlwaysShow = css`
	position: relative;
	left: 0;
	top: 0;
	height: auto;
`;

export const accHide = css`
	${accAlwaysHide};
	&:focus {
		left: 1em;
		height: auto;
	}
`;

/**
 * Undo accHide
 */
export const accShow = css`
	${accAlwaysShow};
	&:focus {
		left: 0;
	}
`;

/**
 * Visually hide text but still keep it available for screen readers
 * useful for image replacement techniques
 */
export const hideText = css`
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
`;

/**
 * Undo hideText
 */
export const showText = css`
	text-indent: 0;
	white-space: normal;
	overflow: auto;
`;

export const hideOutline = css`
	outline: 0;
`;
