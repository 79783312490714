import React from 'react';

import {
	gridContainerAlignItemsCenter,
	gridContainerAlignItemsToEnd,
	gridContainerCenterItems,
	gridContainerCss,
	gridContainerItemsToEnd,
	gridContainerReducedWidth,
	gridContainerReverse,
	gridContainerSingleLine,
	gridContainerSpaceBetweenItems,
	gridContainerStretchItems,
} from '@dop/shared/components/layout/gridContainer';
import { layoutContainerCss } from 'moduleAlias/project/src/components/layout/layoutContainer';

interface GridOptions {
	stretchItems?: boolean;
	alignItemsToEnd?: boolean;
	alignItemsCenter?: boolean;
	centerItems?: boolean;
	itemsToEnd?: boolean;
	spaceBetweenItems?: boolean;
	singleLine?: boolean;
	reverse?: boolean;
	reducedWidth?: boolean;
}
interface Props extends GridOptions {
	className?: string;
	children: React.ReactNode;
}

/**
 * @deprecated - use the page-layout grids in ui-composites to position your page elements.
 */
// eslint-disable-next-line complexity
export const LayoutGridContainer = ({
	className,
	children,
	stretchItems,
	alignItemsToEnd,
	alignItemsCenter,
	centerItems,
	itemsToEnd,
	spaceBetweenItems,
	singleLine,
	reverse,
	reducedWidth,
	...props
}: Props): React.ReactElement | null => {
	if (children == null) return null;

	return (
		<div
			className={[className, 'gridContainer'].join(' ')}
			css={`
				${gridContainerCss};
				${layoutContainerCss};

				${stretchItems ? gridContainerStretchItems : null}
				${alignItemsToEnd ? gridContainerAlignItemsToEnd : null}
				${alignItemsCenter ? gridContainerAlignItemsCenter : null}
				${centerItems ? gridContainerCenterItems : null}
				${itemsToEnd ? gridContainerItemsToEnd : null}
				${spaceBetweenItems ? gridContainerSpaceBetweenItems : null}
				${singleLine ? gridContainerSingleLine : null}
				${reverse ? gridContainerReverse : null}
				${reducedWidth ? gridContainerReducedWidth : null}
			`}
			{...props}
		>
			{children}
		</div>
	);
};
