import { css } from 'styled-components';

/**
 * @deprecated - use the page-layout grids in @dop/shared/components/pageLayout to position your page elements.
 */
export const layoutContainerCss = css`
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-inline: calc(var(--layout-side-space) - 1rem);
`;

/**
 * @deprecated - use the page-layout grids in @dop/shared/components/pageLayout to position your page elements.
 */
export const LayoutContainer = ({ children, id = '', className = '' }) => {
	if (children == null) return null;

	return (
		<div className={className} id={id} css={layoutContainerCss}>
			{children}
		</div>
	);
};
