import { hemelblauw, mosgroen, violet, paars } from '../../../styles/colors';

export const getActualBlockPropsForContentType = (contentType) => {
	switch (contentType) {
		case 'wettenEnRegels':
			return {
				primaryColor: mosgroen.default,
				overviewText: 'Wetten en regels',
			};
		case 'branches':
			return {
				primaryColor: paars.default,
				overviewText: 'Branche-informatie',
			};
		case 'gemeentes':
			return {
				primaryColor: mosgroen.default,
				overviewText: 'Gemeente-informatie',
			};
		case 'landen':
			return {
				primaryColor: violet.default,
				overviewText: 'Landeninformatie',
			};
		case 'evenementen':
			return {
				primaryColor: paars.default,
				overviewText: 'Evenementen en bijeenkomsten',
			};
		case 'webinars':
			return {
				primaryColor: violet.default,
				overviewText: 'Webinars en video’s',
			};
		case 'regelhulpen':
			return {
				primaryColor: mosgroen.default,
				overviewText: 'Regelhulpen',
			};
		case 'verhalen':
			return {
				primaryColor: hemelblauw.default,
				overviewText: 'Ondernemersverhalen',
			};
		case 'actueel':
		default:
			return {
				primaryColor: hemelblauw.default,
				overviewText: 'Uitgelicht',
			};
	}
};
