import { css } from 'styled-components';

export const noListSpacing = css`
	padding-left: 0;
	margin-bottom: 0;
	margin-left: 0;
`;

export const noBullets = css`
	${noListSpacing};

	list-style: none;
`;
