import { createStyleObject } from '@capsizecss/core';

import { fontMetrics, rootCapInPx } from './fontDefinitions';
import { CapHeight, LeadingRatio } from '../typography/typographyDefinitions';

export type RootCapStyling = {
	fontSize: string;
	lineHeight: string;
	'::before': {
		content: string;
		marginBlockEnd: string;
		display: string;
	};
	'::after': {
		content: string;
		marginBlockStart: string;
		display: string;
	};
};

/**
 * Calculates font size styling and corrects spacing
 * around blocks of text using [Capsize](https://seek-oss.github.io/capsize/)
 *
 * Uses the root cap height as a unit of measurement instead of pixels.
 */
export const getRootCapStyling = (
	capHeightInRootCap: CapHeight,
	leadingRatio: LeadingRatio,
): RootCapStyling => {
	const capHeightPx = capHeightInRootCap * rootCapInPx.all;
	const {
		fontSize,
		lineHeight,
		'::before': { marginBottom: beforeMargin, ...beforeStyling },
		'::after': { marginTop: afterMargin, ...afterStyling },
	} = createStyleObject({
		fontMetrics,
		capHeight: capHeightPx,
		leading: capHeightPx * leadingRatio,
	});
	const fontSizeInPx = parseFloat(fontSize);
	const lineHeightInPx = parseFloat(lineHeight);

	const fontSizeInRootCap = fontSizeInPx / rootCapInPx.all;
	const lineHeightInRootCap = lineHeightInPx / rootCapInPx.all;

	return {
		fontSize: `calc(${fontSizeInRootCap} * var(--root-cap))`,
		lineHeight: `calc(${lineHeightInRootCap} * var(--root-cap))`,
		'::before': {
			...beforeStyling,
			marginBlockEnd: beforeMargin,
		},
		'::after': {
			...afterStyling,
			marginBlockStart: afterMargin,
		},
	};
};
