import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { translate } from '@dop/shared/translate/translate';
import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconSearch: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '5 4 55.2 49.69', width: '55.2', height: '49.69' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		version="1.1"
		role="img"
		{...addTestSelector('uiSearchIcon')}
		{...props}
	>
		<title>{translate('search')}</title>
		<path d="M62,55.5369238 L55.8555106,61.6814132 L40.3717098,46.1976124 C36.4048491,49.0055923 31.5603558,50.6556972 26.33046,50.6556972 C12.893118,50.6556972 2,39.7625792 2,26.3252372 C2,12.8878952 12.893118,1.99477727 26.33046,1.99477727 C39.767802,1.99477727 50.6609199,12.8878952 50.6609199,26.3252372 C50.6609199,31.39365 49.1111422,36.1000929 46.4596143,39.9965381 L62,55.5369238 Z M26.33046,43.0226117 C35.5521653,43.0226117 43.0278345,35.5469425 43.0278345,26.3252372 C43.0278345,23.2781522 42.2116342,20.4217009 40.7859366,17.9625864 C37.8968996,12.9794312 32.5050802,9.62786275 26.33046,9.62786275 C17.1087547,9.62786275 9.63308548,17.1035319 9.63308548,26.3252372 C9.63308548,35.5469425 17.1087547,43.0226117 26.33046,43.0226117 Z" />
	</svg>
);
