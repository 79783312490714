import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { get } from '@dop/shared/helpers/immutableHelpersWithArgs';
import { mapToComponent } from '@dop/shared/helpers/reactHelpers';
import { IconChevronRight } from '@dop/ui-icons/svg/chevronRight';
import { existy, unexisty } from '@dop/shared/helpers/functional';
import { LayoutGridContainer } from '@dop/shared/components/layout/layoutGridContainer';
import { createUnderliner } from '@dop/shared/components/link/blockElements';
import { Media } from '@dop/shared/components/media/media';

import { wit, zwart, donkerblauw } from '../../../styles/colors';
import { ColumnQuarter } from '../../layout/columnQuarter';
import { defaultTheme } from '../../../styles/defaultTheme';
import { Link, ExternalLinkIcon } from '../../link/link';
import { Heading } from '../heading';
import { hoverEtc } from '../../../styles/mixins/pseudo';
import { getActualBlockPropsForContentType } from './getActualBlockPropsForContentType';
import { addTestSelector } from '@dop/shared/helpers/testSelector';

export const ChevronStyled = styled(IconChevronRight)`
	width: 1.2rem;
	height: 1.2rem;
	margin: 0.2rem 0.6rem 0 -0.2rem;
	fill: currentColor;
`;

const shadowTransparency = 0.91;

export const ActualBlockStyled = styled.div`
	flex: auto;
	display: flex;
	flex-direction: column;
	margin: ${defaultTheme.halfSpace} 0 ${defaultTheme.space} 0;
	border-top: 3px solid;
	border-top-color: ${({ $primaryColor }) => $primaryColor};
	background-color: ${wit};
	box-shadow: 0 3px 19px 0 ${transparentize(shadowTransparency, zwart)};
	transition-duration: 200ms;
	transition-property: box-shadow, transform;
	&:hover {
		box-shadow: 0 3px 19px 3px ${transparentize(shadowTransparency, zwart)};
		transform: translateY(-2px);
	}
`;

export const sidePaddingStyle = css`
	padding-left: 1.5rem;
	padding-right: 1.5rem;
`;

const ActualBlockOverviewStyle = css`
	color: ${({ $primaryColor }) => $primaryColor};
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	display: block;
	border: none;
	line-height: 1.2;
	flex: none;
	order: -1;
`;

const ActualBlockOverviewLink = styled(Link)`
	${ActualBlockOverviewStyle};
	${sidePaddingStyle};

	${hoverEtc`
		color: ${({ $primaryColor }) => $primaryColor};
	`};
`;

const ActualBlockOverviewText = styled.span`
	${ActualBlockOverviewStyle};
`;

ActualBlockOverviewLink.Underliner = createUnderliner(ActualBlockOverviewLink);

const ActualBlockLink = styled(Link)`
	flex: auto;
	display: flex;
	flex-direction: column;
	border: none;
	${sidePaddingStyle};
	color: inherit;
	padding-bottom: 0.75rem;

	${hoverEtc`
		color: inherit;
	`};
`;

ActualBlockLink.Underliner = createUnderliner(ActualBlockLink);
const ActualBlockTitle = styled(Heading).attrs(() => ({
	level: 3,
}))`
	margin-top: 0;
	margin-bottom: 0.75rem;
	color: ${donkerblauw.default};
	flex: none;
`;
const ActualBlockMetaTitle = styled.div`
	font-weight: bold;
	margin-top: 0;
	margin-bottom: 0.75rem;
	flex: none;
`;

const transparentWhite = transparentize(1, wit);
export const ActualBlockIntro = styled.div`
	margin-top: 0;
	margin-bottom: 0.75rem;
	flex: auto;
	position: relative;
	height: 6rem;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 3rem;
		background-image: linear-gradient(to top, ${wit}, ${transparentWhite});
		pointer-events: none;
	}
`;

const ActualBlockCallToActionStyled = styled(Media).attrs(() => ({
	alignItems: 'flex-start',
}))`
	color: ${donkerblauw.default};
	flex: none;
`;
export const ActualBlockCallToAction = ({ children }) => {
	return (
		<ActualBlockCallToActionStyled>
			<Media.Figure>
				<ChevronStyled />
			</Media.Figure>
			<Media.Text>
				<ActualBlockLink.Underliner>{children}</ActualBlockLink.Underliner>
			</Media.Text>
		</ActualBlockCallToActionStyled>
	);
};

const ActualBlockColumn = styled(ColumnQuarter)`
	display: flex;
	flex-direction: column;
`;

const ActualBlock = ({
	contentType,
	title,
	metaTitle,
	intro,
	link,
	overviewLink,
}) => {
	const { primaryColor, overviewText } =
		getActualBlockPropsForContentType(contentType);

	const overviewLinkHref = get('href')(overviewLink);

	return (
		<ActualBlockColumn>
			<ActualBlockStyled $primaryColor={primaryColor}>
				<ActualBlockLink
					to={get('href')(link)}
					linkType="homeActueelLink"
					checkExternal={false}
					{...addTestSelector('uiHomeActueelLink')}
				>
					<ActualBlockTitle>
						<ActualBlockLink.Underliner>{title}</ActualBlockLink.Underliner>
					</ActualBlockTitle>
					{metaTitle && (
						<ActualBlockMetaTitle>{metaTitle}</ActualBlockMetaTitle>
					)}
					{intro && <ActualBlockIntro>{intro}</ActualBlockIntro>}
					<ActualBlockCallToAction>
						<ActualBlockLink.Underliner>
							{get('text')(link)}
						</ActualBlockLink.Underliner>
						<ExternalLinkIcon
							linkTo={get('href')(link)}
							css={`
								vertical-align: baseline;
							`}
						/>
					</ActualBlockCallToAction>
					{unexisty(overviewLinkHref) && (
						<ActualBlockOverviewText $primaryColor={primaryColor}>
							{overviewText}
						</ActualBlockOverviewText>
					)}
				</ActualBlockLink>
				{existy(overviewLinkHref) && (
					<ActualBlockOverviewLink
						to={overviewLinkHref}
						linkType="homeActueelOnderwerpLink"
						$primaryColor={primaryColor}
						{...addTestSelector('uiHomeActueelOnderwerpLink')}
					>
						<ActualBlockOverviewLink.Underliner>
							{overviewText}
						</ActualBlockOverviewLink.Underliner>
					</ActualBlockOverviewLink>
				)}
			</ActualBlockStyled>
		</ActualBlockColumn>
	);
};

const ActualBlockListContainer = styled(LayoutGridContainer)`
	&& {
		align-items: stretch;
	}
`;

export const ActualBlockList = ({ blockList }) => {
	return (
		<ActualBlockListContainer>
			{mapToComponent(ActualBlock)(blockList)}
		</ActualBlockListContainer>
	);
};
