'use client';
import React from 'react';

import { Main } from '@dop/shared/components/layout/main';

import { HomeTopTasks } from '../display/home/homeTopTasks';
import { HomeThemaLinks } from '../display/home/homeThemaLinks';
import { ActualBlock } from '../display/home/actualBlock';
import { HomeCoronaBanner } from '../corona/homeCoronaBanner';

export const HomePage = () => (
	<Main $padding={[0, 0, 4, 0]}>
		<HomeCoronaBanner />
		<HomeTopTasks />
		<HomeThemaLinks />
		<ActualBlock />
	</Main>
);

export default HomePage;
