import React from 'react';
import { css } from 'styled-components';

/**
 * @deprecated - use the page-layout grids in @dop/shared/components/pageLayout to position your page elements.
 */
// Similar to LayoutGridContainer, but with no Columns inside, so no need for the adjustment of 1rem to the padding-inline.
export const contentContainerCss = css`
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-inline: var(--layout-side-space);
`;

interface Props {
	className?: string;
	children: React.ReactNode;
}

/**
 * @deprecated - use the page-layout grids in ui-composites to position your page elements.
 */
export const ContentContainer = ({
	className,
	children,
	...props
}: Props): React.ReactElement | null => {
	if (children == null) return null;

	return (
		<div
			css={contentContainerCss}
			className={['contentContainer', className].join(' ')}
			{...props}
		>
			{children}
		</div>
	);
};
