import { darken } from 'polished';

export const donkerblauw = {
	default: '#01689B',
	hover: '#01476B',
};

export const rainbow = {
	paars: '#aa418c',
	oranje: '#ff9300',
};
export const hemelblauw = {
	default: '#0077c0', // default -> hemelblauw, old value was #007bc7
	darkest: '#033054', // bestaat niet in Rijkshuisstijl
	darker: '#2d526c', // introduced for English
	bright: '#1575b5',
	official: '#154273', // official blue color Rijkslogo
	soft: '#4983c6',
	light: '#8fcae7', // default -> licht blauw
	light2: '#d9edf6',
	lighter: '#e2f0f7', // light -> licht blauw
	lighter2: '#ebf6fb',
	lightest: '#f3f9ff', // lichtst -> gebruikt in detailPagesBlock
	grijscontrast: '#066eaf',
	hover: darken(0.07, '#0077c0'), // 7% darker than default
};
export const violet = {
	darker: '#75154b',
	default: '#a90061',
	light: '#e5b2cf',
	lightest: '#f4e9f1',
	hover: '#8f0051',
};

export const mosgroen = {
	darker: '#686b00',
	default: '#727800', // old value was #777c00
	light: '#d6d7b2',
	lightest: '#eeeed7',
};

export const paars = {
	darker: '#310d47',
	default: '#42145f',
	bright: '#6A00AC',
	light: '#5f3977',
	lightest: '#d9c7e3',
	lightest2: '#f1ebf5',
};

export const grijs = {
	// alle grijstinten wijken af van Rijkshuisstijl
	darkest: '#333333',
	grijs6: '#696969', // uit Rijkhuisstijl (5.48 contrast op wit)
	grijs7: '#777772', // 4.5 contrast op wit
	default: '#8f8f8f',
	rightcolumn: '#444444',
	border: '#e5e5e5',
	light: '#dddddd',
	lightest: '#f6f6f6',
};
// (Formulier) meldingen: valid
export const groen = {
	default: '#39870c',
	light: '#c3dbb6',
	lightest: '#efeddb',
};

export const deepgroen = {
	default: '#02836F', // 4.6 contrast met witte tekst
	border: '#cce5e2',
	light: '#f3f8f7',
};

// (Formulier) meldingen: invalid
export const rood = {
	hover: '#b7251a',
	default: '#d52b1e',
	light: '#f2bfbb',
	lightest: '#f9dfdd',
};

export const zwart = '#000000';
export const wit = '#ffffff';

export const oranje = {
	darker: '#ba5c2c',
	default: '#E37237',
	light: '#e88756',
	lightest: '#ffeadf',
};

export const aqua = {
	default: '#00857c',
};

export const mvp = {
	text: '#343434',
	background: '#f3f3f3',
	toast: '#efefee', //'#f9f9fb',
	line: '#bdbdbd',
	lineBlueish: '#a1b8cf',
	lineGrayish: '#5E718A',
	footer: '#efefef',
	icons: '#555D65',
};

export const focus = {
	default: '#4d91fe',
};

// used for CBS-page feiten-en-cijfers
export const cbsBrandColor = {
	default: '#271D6C',
};

export const contentLinkColor = {
	default: donkerblauw.default,
	hover: donkerblauw.hover,
};
