import React from 'react';
import classnames from 'classnames';

export const ColumnHalf = ({ children, className = undefined }) => {
	if (children == null) return null;

	return (
		<div className={classnames('column column--half', className)}>
			{children}
		</div>
	);
};
