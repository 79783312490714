import React from 'react';

import { accHide } from 'moduleAlias/project/src/styles/mixins/accHide';

export const AccHide = ({
	children,
	...props
}: {
	children: React.ReactNode;
}): React.ReactElement | null => {
	if (children == null) return null;

	return (
		<span
			{...props}
			css={`
				${accHide};
			`}
		>
			{children}
		</span>
	);
};
